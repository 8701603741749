<template>
	<page-layout bgColor="#f2f2f2" page-name="产品列表">
		<div class="wrapper">
			<item-card v-for="item in productList" :key="item.id" :product="item"></item-card>
		</div>
	</page-layout>
</template>

<script>
	import ItemCard from './ItemCard'
	import ProductApi from '@/api/product'

	export default {
		components: {ItemCard},
		data() {
			return {
				productList: null
			}
		},
		mounted() {
			ProductApi.allProcucts().then(res => {
				this.productList = res.data
			})
		}
	}
</script>

<style scoped>
	.wrapper {
		padding: 20px;
	}
</style>